import React, { useState } from 'react';
import { Input, Stack, Button } from '@mui/joy'
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const [code, setCode] = useState("");

    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(`/${code}`);
    };

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
        >
            <h1>Enter your unique RSVP Code</h1>
            <Stack direction="row" spacing={2}>
                <Input placeholder="RSVP Code" value={code} onChange={(e) => setCode(e.target.value)} />
                <Button onClick={() => handleSubmit()}>Submit</Button>
            </Stack>
        </Stack>
    );
};

export default LandingPage;