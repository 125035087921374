import React from 'react';
import { DataProvider } from './context/DataProvider';
import LandingPage from './pages/LandingPage/LandingPage';
import RsvpPage from './pages/RsvpPage/RsvpPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  // const [data, setData] = useState(null);

  // const LandingPage = () => {
  //   const [code, setCode] = useState("");
  //   const getRSVP = async () => {
  //     try {
  //       const res = await fetch(`/api/rsvp/${code}`);
  //       const data = await res.json();
  //       setData(data);
  //     }
  //     catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   console.log(code);
  //   return (
  //     <div>
  //       <h1>Home Page</h1>
  //       {/* <form onSubmit={() => { getRSVP() }}> */}
  //         <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
  //         {/* <input type="submit" value="Submit" /> */}
  //         <button onClick={()=>getRSVP()}>Submit</button>
  //       {/* </form> */}
  //     </div>
  //   )
  // }

  // const RsvpPage = (data) => {
  //   return (
  //     <div>
  //       <h1>RSVP Page</h1>
  //       {data && (
  //         <pre>{JSON.stringify(data, null, 2)}</pre>
  //       )}
  //     </div>
  //   )
  // }


  return (
    <div className="App">
      <DataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/:code" element={<RsvpPage/>} />
          </Routes>
        </Router>
      </DataProvider>
    </div>
  );
}

export default App;
