import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDataContext } from '../../context/useDataContext';
import { Box, Stack, Radio, RadioGroup } from '@mui/joy'

const RsvpPage = () => {
    const { code } = useParams();
    const { data: dataVal, setData } = useDataContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/rsvp/${code}`);
                const data = await response.json();
                setData(data);
                // console.log(data);
            } catch (error) {
                // Handle error here
            }
        };

        fetchData();
    }, [code]);

    let headers = [];
    useEffect(() => {
        console.log(dataVal);
        headers = Object.keys(dataVal);
    }, [dataVal]);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <h1>{code}</h1>
            <div>
                {Object.keys(dataVal).map((key, index) => (
                    <Box key={index} 
                    width={300}
                    my={4}
                    alignItems="center"
                    gap={4}
                    p={2}
                    sx={{ border: '2px solid grey' }}>
                        <h3>{key}</h3>
                            <Stack direction="column" spacing={1}>
                            {dataVal[key].map((item, idx) => (
                                <Stack key={idx} direction="row" justifyContent={"space-between"} spacing={1}>
                                    <strong>{item.name}</strong>
                                    <RadioGroup name="radio-buttons-group" orientation="horizontal">
                                        <Radio value={true} label="Yes" variant="outlined" />
                                        <Radio value={false} label="No" variant="outlined" />
                                    </RadioGroup>
                                </Stack>
                            ))}
                            </Stack>

                    </Box>
                ))}
            </div>
        </Stack>
    );
};

export default RsvpPage;

