import React, { createContext, useState } from 'react';

// Create a new context
export const DataContext = createContext(null);

// Create a data provider component
export const DataProvider = ({ children }) => {
    // Define your state and functions here
    const [data, setData] = useState({});
    // Provide the state and functions to the children components
    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    );
};